/* الحاوية الخاصة بشريط التحميل */
.loading-bar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh; /* يمكن تغييره حسب رغبتك */
}

/* الشريط الخارجي */
.loading-bar {
  width: 100%; /* العرض الكامل للشريط */
  max-width: 600px; /* أقصى عرض للشريط */
  height: 10px; /* سماكة الشريط */
  background-color: #e0e0e0; /* لون الخلفية للشريط */
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

/* الشريط المتحرك الداخلي */
.loading-progress {
  width: 50%; /* الشريط المتحرك يأخذ 50% من عرض الشريط الخارجي */
  height: 100%;
  background-color: #3498db; /* لون الشريط المتحرك */
  border-radius: 5px;
  position: absolute;
  animation: slide 1.5s linear infinite; /* الحركة المستمرة */
}

/* حركة الشريط المتحرك */
@keyframes slide {
  0% {
    left: -50%; /* يبدأ من خارج الشريط */
  }
  100% {
    left: 100%; /* ينتهي خارج الشريط من الجانب الآخر */
  }
}
