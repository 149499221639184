@import url("https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap");

body {
  font-family: "Tajawal", sans-serif;
  font-weight: 400;
  font-style: normal;
}
:disabled {
  opacity: 75%;
  cursor: not-allowed;
}
.disableBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}

.loader {
  border-top-color: #3498db;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* مؤشر تحميل بسيط */
.loader-in-page {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin-in-page 1s linear infinite;
  -webkit-animation: spin-in-page 1s linear infinite;
}

@keyframes spin-in-page {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
